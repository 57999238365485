import Gmap from "./components/GoogleMaps.js";
import GPTAssistant from "./components/GPT_Assistnat.js";

export default function Assistant() {
    return (
        <div className="flex justify-between">
            <div className="w-1/2 bg-gray-200 p-4">
                <GPTAssistant />
            </div>
            <div className="w-1/2">
                <Gmap />
            </div>
        </div>
    );
}