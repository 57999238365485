import React from "react";

const ResponseCard = ({ destination, flight, hotel, description, image }) => (
  <div
    className="bg-[white] rounded-[8px] mt-[50px] p-[20px] mx-auto text-black block md:flex gap-[50px]"
    style={{
      boxShadow: "0px 8px 14px 10px rgba(56, 56, 64, 0.10)",
      backdropFilter: "blur(15px)",
      maxWidth: "750px",
    }}
  >
    <div className="">
      <img
        className="rounded-[8px] max-w-[273px] w-full max-h-[153px] "
        src={image}
        alt={destination}
      />
      <h2 className="text-3xl my-[10px] text-[#2822B1] font-bold mb-2">
        {destination}
      </h2>
      <p className="max-w-[500px]">
        {description === " " || !description
          ? "An alluring destination combining cultural richness with natural beauty, offering a serene escape and a harmonious blend of tradition and modernity."
          : description}
      </p>
    </div>
    <div className="flex flex-col justify-between max-w-[500px] w-full">
      <div className="flex flex-col justify-between">
        <div className="flex flex-col mt-4">
          <h3 className="text-md text-[#728FF3] font-bold mb-2">
            <i class="fa-solid fa-plane"></i> Flight Information
          </h3>
          <div className="flex justify-between">
            <span className="font-semibold">Price:</span>
            <p className="text-sm">${Number(flight.price)?.toFixed(2)}</p>
          </div>
          <div className="flex justify-between my-3">
            <span className="font-semibold">Segments:</span>
            <p className="text-sm">{flight.segments}</p>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Travel Time:</span>
            <p className="text-sm">{flight.travelTime}</p>
          </div>
        </div>
        <div className="flex flex-col  mt-4">
          <h3 className="text-md text-[#728FF3] font-bold mb-2">
            <i class="fa-solid fa-bed"></i> Hotel Information
          </h3>
          <div className="flex justify-between">
            <span className="font-semibold">Price:</span>
            <p className="text-sm">${Number(hotel.price)?.toFixed(2)}</p>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Name:</span>
            <p className="text-sm">{hotel.hotelName}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <p className="text-xl text-[#728FF3] font-bold mt-4">Total:</p>
        <p className="text-xl text-[#728FF3] font-bold mt-4">
          ${Math.round(Number(flight.price) + Number(hotel.price))?.toFixed(2)}
        </p>
      </div>
    </div>
  </div>
);

export const ResponsePage = ({ data }) => {
  const [showErrorPopup, setShowErrorPopup] = React.useState(false);
  // console.log("Incoming Data:", data); // Here's the log statement


  return (
    <div className="response-page flex flex-col items-center py-2">
      {data.map((item, index) => {
        if (item.statusCode === 500) return null; // do not display items with status code 500
        console.log(item);
        const flightError = item.flights?.error;
        const hotelError = item.hotels?.error;
        const flight = item.flights?.flight;
        const hotel = item.hotels?.hotel;
        const destination = item.destinations?.location;
        const description = item.destinations?.description;
        const image = item.destinations?.image;

        if (flightError || hotelError) {
          return (
            <div key={index} className="text-red-500 mt-2">
              Error: {flightError || hotelError}
            </div>
          );
        }

        if (!flight || !hotel || !destination || !description) {
          setShowErrorPopup(true); // show error popup
          return (
            <div key={index} className="text-red-500 mt-2">
              Data not available for this destination
            </div>
          );
        }

        return (
          <ResponseCard
            key={index}
            destination={destination}
            flight={flight}
            hotel={hotel}
            description={description}
            image={image}
          />
        );
      })}

      {showErrorPopup && (
        <div className="error-popup bg-white shadow-md rounded p-6 mt-4">
          <p>An error occurred. Please try again later.</p>
          <button
            onClick={() => setShowErrorPopup(false)}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default ResponsePage;
