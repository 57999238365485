import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Homepage";
import SearchPage from "./UserInputForm";
import Navbar from "./NavBar";
import Assistant from "./Assistant";



function App() {
  return (
    <div
      className="pt-[38px]"
      style={{
        backgroundImage:
          "linear-gradient(-135deg, white , white, white, white, white)",
      }}
    >
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/assistant" element={<Assistant />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
