import { useEffect, useRef, useState } from "react";

import EmailSection from "./components/EmailSection";

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
];

export default function HomePage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [heroW, setHeroW] = useState(500);
  const elementRef = useRef(null);

  useEffect(() => {
    const getClientWidth = () => {
      if (elementRef.current) {
        const width = elementRef.current.clientWidth;
        setHeroW(width);
        console.log(heroW);
      }
    };

    getClientWidth();

    const handleResize = () => {
      getClientWidth();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="bg-transparent py-10 md:px-[20px] lg:px-[7.5%]"
        style={{
          backgroundImage:
            "linear-gradient(-135deg, white , white, white, white, white)",
        }}
      >
        <div className="bg-[transparent] py-10">
          <div className="mx-auto flex flex-wrap md:flex-nowrap justify-between items-center gap-[50px] md:gap-[100px]">
            <div
              className=" z-10 text-left font-[Outfit] mx-[auto] md:mx-0"
              style={{ width: `${heroW}px` }}
            >
              <p
                className="text-[#728ff3] text-[2em] sm:text-[3em] md:text-[1.5em] xl:text-[3em] 2xl:text-[5em] font-bold tracking-[1.76px] mb-[-9px]"
                style={{
                  textShadow:
                    " -1px -1px 0 #000,   1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
                }}
              >
                Discover
              </p>
              <h1
                ref={elementRef}
                className="text-[#2822B1] my-[5px] w-[max-content] text-[2em] sm:text-[3em] md:text-[2.5em] xl:text-[4em] 2xl:text-[6em] font-bold tracking-[2px]"
              >
                Budget Travel
              </h1>
              <div className="flex gap-[10px] flex-wrap justify-between">
                <hr className="w-[55%] sm:w-[55%] border-[1.2px] opacity-100 self-center ml-[0px] items-center" />
                <h1 className="text-[#8C9BB4] text-[1em] sm:text-[1.25em] lg:text-[2em] ">
                  Made Easy
                </h1>
              </div>
              <p className="text-[#8C9BB4] py-10 text-md">
                WanderWisz is your passport to an integrated travel search
                solution. Find the best flights, hotels, and packages all in one
                place.
              </p>
              <a
                href="/search"
                className="rounded-md bg-[#2822B1] px-9 py-5 font-semibold text-white shadow-md hover:bg-indigo-700"
              >
                Try it now for free
              </a>
              <div className="mt-[100px] ">
                <h1 className="text-[#728FF3] text-[1.5em] sm:text-[2.5em] md:text-[2em] font-[500] mb-[37px]">
                  Our Partners
                </h1>
                <div className="flex flex-wrap gap-[10px] justify-between">
                  <div>
                    <img
                      src="redit.png"
                      className="h-[30px] sm:h-[40px] 2xl:h-[70px]"
                      alt=""
                    />
                    <p className="text-[10px] mt-2">Coming soon</p>
                  </div>
                  <img
                    src="stripe.png"
                    className="h-[30px] sm:h-[40px] 2xl:h-[70px]"
                    alt=""
                  />
                  <div>
                    <img
                      src="spotify.png"
                      className="h-[30px] sm:h-[40px] 2xl:h-[70px]"
                      alt=""
                    />
                    <p className="text-[10px]">Coming soon</p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" grow">
              <img
                src="hero-imgs.png"
                className="w-[90%] md:w-[100%] mx-auto"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container my-24 mx-auto px-[20px]">
        <section class="mb-32 text-center">
          <div class="flex justify-center">
            <div class="max-w-[700px] text-center">
              <h2 class="mb-6 text-center text-[#2822B1] text-3xl font-bold">
                Simplify Travel{" "}
                <span class="text-[#728FF3] dark:text-primary-400">
                  Searches
                </span>
              </h2>
              <p class="mb-16 text-neutral-500 dark:text-neutral-300">
                With WanderWisz, unlock the magic of effortless travel planning.
                Find incredible destinations that fit your budget and
                preferences.
              </p>
            </div>
          </div>

          <div class="flex justify-center gap-[50px] flex-col md:flex-row md:px-[20px] lg:px-[7.5%] ">
            <div class="mb-12 lg:mb-0">
              <div class="mb-6 inline-block rounded-full bg-[#E4EBF6] p-[25px] text-[#2822B1] shadow-sm">
                <i class="fa-solid fa-compass text-[36px]"></i>
              </div>
              <h5 class="mb-4 text-2xl font-bold">Discover</h5>
              <p class="text-neutral-500 dark:text-neutral-300">
                Easily find new destinations with our intelligent AI-powered
                suggestions.
              </p>
            </div>

            <div class="mb-12 lg:mb-0">
              <div class="mb-6 inline-block rounded-full bg-[#E4EBF6] px-[20px] py-[25px] text-[#2822B1] shadow-sm">
                <i class="fa-solid fa-scale-unbalanced text-[36px]"></i>
              </div>
              <h5 class="mb-4 text-2xl font-bold">Compare</h5>
              <p class="text-neutral-500 dark:text-neutral-300">
                Compare flight & hotel prices from trusted providers to ensure
                you get the best deals.
              </p>
            </div>

            <div class="mb-12 md:mb-0">
              <div class="mb-6 inline-block rounded-full bg-[#E4EBF6] px-[20px] py-[25px] text-[#2822B1] shadow-sm">
                <i class="fa-solid fa-plane-departure text-[36px]"></i>
              </div>
              <h5 class="mb-4 text-2xl font-bold">Travel</h5>
              <p class="text-neutral-500 dark:text-neutral-300">
                Pack your bags and set out for a memorable journey, all within
                your budget.
              </p>
            </div>
          </div>
        </section>
      </div>
      <EmailSection />
    </>
  );
}
