import React, { useEffect, useState, useRef } from "react";
import style from "./SearchSection.module.css";

function SearchSection({
  setOrigin,
  setDestination,
  register,
  handleSubmit,
  setValue,
  errors,
  onSubmit,
  loading,
  loadingMessage,
  departureDate,
  returnDate,
  watch,
  originCity,
  destinationCity,
}) {
  const { from, destination } = watch();
  const [isVisible, setIsVisible] = useState(true);

  const handleSwap = () => {
    setValue("from", destination);
    setValue("destination", from);
    setOrigin(destinationCity);
    setDestination(originCity);
  };

  const autoCompleteFrom = useRef(null);
  const autoCompleteDest = useRef(null);

  function handlePlaceSelect(autoComplete, ref) {
    let addressObject = autoComplete.getPlace();
    let matches = addressObject.address_components.filter((address_component) =>
      ["locality", "colloquial_area"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );
    if (matches.length > 0) {
      if (ref === "From") {
        setOrigin(matches[0].long_name);
      } else if (ref === "Destination") {
        setDestination(matches[0].long_name);
      }
    } else {
      console.log(
        "No matching locality or colloquial_area found in address_components."
      );
    }
  }

  useEffect(() => {
    const loadScript = (url, callback) => {
      // Check if the script is already present in the document
      if (document.querySelector(`script[src="${url}"]`)) {
        // If the script is already present, just call the callback
        callback();
      } else {
        // Otherwise, add the script to the document
        let script = document.createElement("script");
        script.type = "text/javascript";
        if (script.readyState) {
          script.onreadystatechange = function () {
            if (
              script.readyState === "loaded" ||
              script.readyState === "complete"
            ) {
              script.onreadystatechange = null;
              callback();
            }
          };
        } else {
          script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    };

    const handleScriptLoad = () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        autoCompleteFrom.current = new window.google.maps.places.Autocomplete(
          document.getElementById("autocompleteFrom"),
          { types: ["(cities)"] }
        );
        autoCompleteFrom.current.setFields([
          "address_components",
          "formatted_address",
        ]);
        autoCompleteFrom.current.addListener("place_changed", () =>
          handlePlaceSelect(autoCompleteFrom.current, "From")
        );

        autoCompleteDest.current = new window.google.maps.places.Autocomplete(
          document.getElementById("autocompleteDest"),
          { types: ["(cities)"] }
        );
        autoCompleteDest.current.setFields([
          "address_components",
          "formatted_address",
        ]);
        autoCompleteDest.current.addListener("place_changed", () =>
          handlePlaceSelect(autoCompleteDest.current, "Destination")
        );
      }
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS}&libraries=places`,
      handleScriptLoad
    );
  }, []);

  return (
    <section
      className="search-section bg-white lg:w-[85%] md:mx-[20px] lg:mx-auto rounded-[8px] my-[150px] py-10 px-[30px] sm:px-[40px] md:px-[30px]"
      style={{
        boxShadow: "0px 8px 14px 10px rgba(56, 56, 64, 0.10)",
        backdropFilter: "blur(15px)",
      }}
    >
      <h2 className="section-title text-xl sm:text-3xl text-[#2822B1] font-bold mb-10">
        Find out an estimated cost for your dream destinations
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        className="mx-auto space-y-8"
      >
        <div>
          <div className="flex gap-[20px] mb-[-20px]">
            {["adults", "starRating"].map((field, index) => (
              <div key={index} className="space-y-2">
                <label
                  htmlFor={field}
                  className="block text-sm font-medium text-gray-700"
                >
                  {index === 0 ? "Number of Adults" : "Star Rating"}
                </label>
                <select
                  {...register(field, {
                    required: `Number of ${field} is required`,
                    valueAsNumber: true,
                    ...(index === 1 ? { min: 1, max: 5 } : {}),
                  })}
                  defaultValue={index === 0 ? 1 : 3}
                  className="w-[75px]"
                  id={field}
                  name={field}
                >
                  {index === 0 ? (
                    // Options for number of adults
                    <>
                      <option value={1}>1 adult</option>
                      <option value={2}>2 adults</option>
                      <option value={3}>3 adults</option>
                      {/* Add more options as needed */}
                    </>
                  ) : (
                    // Options for star ratings
                    <>
                      <option value={1}>1 star</option>
                      <option value={2}>2 stars</option>
                      <option value={3}>3 stars</option>
                      <option value={4}>4 stars</option>
                      <option value={5}>5 stars</option>
                    </>
                  )}
                </select>
                {errors[field] && (
                  <p className="text-red-500 text-xs italic">
                    {errors[field].message}
                  </p>
                )}
              </div>
            ))}
            <div>
              <p class="text-sm mb-2 font-medium text-gray-700">
                Receive recommendations
              </p>
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsVisible(!isVisible);
                    setDestination('');
                    setValue("destination", '');
                  }}
                  value=""
                  class="sr-only peer"
                  defaultChecked={true}
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
        </div>

        <div className={`${style.filter_container}`}>
          <div className="flex gap-[20px] justify-center items-center flex-col md:flex-row">
            <div className={`w-full md:w-auto space-y-2`}>
              <div className="relative">
                <input
                  type="text"
                  onChange={(event) => {
                    setValue("from", event.target.value);
                  }}
                  placeholder={"From"}
                  id="autocompleteFrom"
                  {...register("from")}
                  className="w-full h-[50px] px-[10px] bg-[white] border border-[#000] rounded pl-10"
                />
                <i className="fa-solid fa-plane absolute top-[50%] transform -translate-y-1/2 left-3 text-gray-500"></i>
              </div>

              {errors.from && (
                <p className="text-red-500">{errors.from.message}</p>
              )}
              {errors.from && (
                <p className="text-red-500 text-xs italic">
                  {errors.from.message}
                </p>
              )}
            </div>
            {/* {isVisible && ( */}
              <div className={`flex gap-[20px] ${!isVisible && 'invisible w-0 h-0  m-[-10px]'}`}>
              <>
                <button
                  type="button"
                  onClick={handleSwap}
                  className="bg-[white] border border-[#000] my-[-40px] z-[20] md:my-[0] md:mx-[-10px] h-[50px] hover:bg-grey text-[grey] font-bold py-2 px-2 rounded w-[50px]"
                >
                  <i class="fa-solid fa-arrow-right-arrow-left rotate-90 md:rotate-0"></i>
                </button>
                <div className={`w-full md:w-auto space-y-2`}>
                  <div className="relative">
                    <input
                      type="text"
                      onChange={(event) =>
                        setValue("destination", event.target.value)
                      }
                      placeholder={`Destination City`}
                      id="autocompleteDest"
                      {...register("destination")}
                      className="w-full h-[50px] px-[10px] bg-[white] border border-[#000] rounded pl-10"
                    />
                    <i className="fa-solid fa-plane absolute top-[50%] transform -translate-y-1/2 left-3 text-gray-500"></i>
                  </div>

                  {errors.destination && (
                    <p className="text-red-500 text-xs italic">
                      {errors.destination.message}
                    </p>
                  )}
                </div>
                </>
                </div>
            {/* )} */}
          </div>
          <div className="flex gap-[20px] justify-center items-center flex-col md:flex-row">
            <div className=" w-full md:w-auto space-y-2">
              <input
                type="date"
                {...register("departureDate", {
                  required: `Departure date is required`,
                })}
                defaultValue={departureDate}
                className="w-full h-[50px] px-[10px] bg-[white] border border-[#000] rounded px-2"
              />
              {errors.departureDate && (
                <p className="text-red-500 text-xs italic">
                  {errors.departureDate.message}
                </p>
              )}
            </div>
            <div className="w-full md:w-auto space-y-2">
              <input
                type="date"
                {...register("returnDate", {
                  required: `Return date is required`,
                })}
                defaultValue={returnDate}
                className="w-full h-[50px] px-[10px] bg-[white] border border-[#000] rounded px-2"
              />
              {errors.returnDate && (
                <p className="text-red-500 text-xs italic">
                  {errors.returnDate.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center justify-start ml-auto w-full md:w-auto">
            <button
              type="submit"
              className="bg-[#2822B1] h-[50px] hover:bg-indigo-400 text-[white] font-bold py-2 px-2 rounded-[10px] w-full md:w-[50px] "
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
            {loading && (
              <div className="text-black text-lg">{loadingMessage}</div>
            )}
          </div>
        </div>

        <div className={`${style.container}`} style={{ display: "" }}>
          {/* <div className="input_field">
            <label htmlFor="choice" className="text-lg text-black"></label>
            <div>
              <input
                type="checkbox"
                id="recommend"
                {...register("choice")}
                onChange={() => {
                  setIsVisible(!isVisible);
                }}
                value="recommend"
                className="mr-2"
              />
              <label htmlFor="recommend">Receive recommendations</label>
            </div>
          </div> */}
          {!isVisible && (
            <div className="space-y-2">
              <label
                htmlFor="idealVacation"
                className="text-lg text-black"
                style={{ whiteSpace: "nowrap" }}
              >
                Describe your desired vacation:
              </label>
              <textarea
                {...register("idealVacation")}
                placeholder="E.g., I want to go to a European city where I can relax and enjoy beautiful scenery"
                className="w-full h-[100px] px-[10px] bg-[white] border border-[#000] rounded px-2"
              />
              {errors.idealVacation && (
                <p className="text-red-500 text-xs italic">
                  {errors.idealVacation.message}
                </p>
              )}
            </div>
          )}
        </div>
      </form>
    </section>
  );
}

export default SearchSection;
