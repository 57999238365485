import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav
      className="bg-[rgba(255, 255, 255, 0.90)]
     px-[15px] sm:px-[55px] flex h-[86px] lg:w-[85%]
      rounded-[8px] bg-white mx-[auto] md:mx-[20px] lg:mx-auto wd-full items-center"
    >
      <Link
        to="/"
        className="text-gray-900 mx-auto sm:mx-[0] text-xl font-bold no-underline"
      >
        <img
          src="WanderWisz.png"
          alt="Wanderwisz"
          className="h-[48px] w-auto "
        />
      </Link>
    </nav>
  );
}

export default Navbar;
