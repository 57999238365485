import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ResponsePage } from "./ResponsePage";
import EmailSection from "./components/EmailSection";
import SearchSection from "./components/searchsection/SearchSection";
import ValueSection from "./components/threevalues/ThreeValues";

export const TravelForm = () => {
  const [results, setResults] = useState(null);
  const [choice, setChoice] = useState("recommend");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [advancedOptions, setAdvancedOptions] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [originCity, setOriginCity] = useState("");
  const [destinationCity, setDestinationCity] = useState("");

  // Defining functions for child component to update form values from the search section
  const setOrigin = (city) => {
    setOriginCity(city);
  };

  const setDestination = (city) => {
    setDestinationCity(city);
  };

  const handleAdvancedOptionsChange = () => {
    setAdvancedOptions(!advancedOptions); // toggle advanced options
  };

  // Calculate dates for departure and return
  const departureDate = new Date();
  departureDate.setDate(departureDate.getDate() + 14); // Next Week
  const returnDate = new Date(departureDate.getTime());
  returnDate.setDate(departureDate.getDate() + 7); // 7 days from departure
  const formattedReturn = returnDate.toISOString().split("T")[0];
  const formattedDeparture = departureDate.toISOString().split("T")[0];

  const [error, setError] = React.useState(null);


  const onSubmit = (data) => {
    console.log(data);
    const requestData = {
      origin: originCity,
      destination: destinationCity,
      ideal_vacation: data.idealVacation,
      departure_date: data.departureDate,
      return_date: data.returnDate,
      adults: data.adults || "1",
      hotel_rating: data.starRating || "3",
    };
    console.log(requestData);

    // http://localhost:3000/recs https://travelreccomender.ew.r.appspot.com https://reccomender-nodejs-dot-travelreccomender.ew.r.appspot.com/recs may need to change port
    setLoading(true);
    setLoadingMessage("Fetching data...");
    setError(null); // Reset error state on new request
    fetch(
      "https://reccomender-nodejs-dot-travelreccomender.ew.r.appspot.com/recs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    )
      .then((response) => {
        if (!response.ok) {
          // If the response is not ok, try to get the error message from the response
          console.log(response);
          return response
            .json()
            .then((err) =>
              Promise.reject(
                new Error(err.error || `HTTP error! status: ${response.status}`)
              )
            );
        }
        // If the response was ok, parse the JSON data from the response.
        return response.json();
      })
      .then((data) => {
        setResults(data);
        setLoading(false);
        setLoadingMessage("");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setLoadingMessage("");
        setResults(null); // clear results if an error occurs
        setError(error.message); // update the error state with the error message
      });
  };

  const handleChoiceChange = (event) => {
    setChoice(event.target.value);
  };

  let content;
  if (error) {
    content = (
      <>
        <div className="text-white bg-red-500 p-3 rounded-md mt-4 text-center text-xl font-bold">
          {error}
        </div>
        <SearchSection
          setOrigin={setOrigin}
          setDestination={setDestination}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          onSubmit={onSubmit}
          setValue={setValue}
          handleChoiceChange={handleChoiceChange}
          handleAdvancedOptionsChange={handleAdvancedOptionsChange}
          choice={choice}
          advancedOptions={advancedOptions}
          departureDate={formattedDeparture}
          returnDate={formattedReturn}
          loading={loading}
          loadingMessage={loadingMessage}
          watch={watch}
          originCity={originCity}
          destinationCity={destinationCity}
        />
      </>
    );
  } else if (results) {
    content = <ResponsePage data={results} />;
  } else {
    content = (
      <>
        <SearchSection
          setOrigin={setOrigin}
          setDestination={setDestination}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          onSubmit={onSubmit}
          setValue={setValue}
          handleChoiceChange={handleChoiceChange}
          handleAdvancedOptionsChange={handleAdvancedOptionsChange}
          choice={choice}
          advancedOptions={advancedOptions}
          departureDate={formattedDeparture}
          returnDate={formattedReturn}
          loading={loading}
          loadingMessage={loadingMessage}
          watch={watch}
          originCity={originCity}
          destinationCity={destinationCity}
        />

        <ValueSection />
        <EmailSection />
      </>
    );
  }

  return <div className="">{content}</div>;
};

export default TravelForm;
