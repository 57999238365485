import React from "react";
import { CalendarDaysIcon, HandRaisedIcon } from "@heroicons/react/24/outline";

function EmailSection() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event.target.elements);
    const { email, feedback, noUpdates } = event.target.elements;
    let details = {
      email: email.value,
      message: feedback.value,
      noUpdates: noUpdates.checked,
    };
    let response = await fetch(
      "https://email-server-dot-travelreccomender.ew.r.appspot.com/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      }
    ).catch((error) => {
      console.error("Error:", error);
    });
    //setStatus("Submit");
    let result = await response.json();
    alert(result.status);
  };

  return (
    <div
      className="relative overflow-hidden py-16  sm:py-24 lg:py-32 md:px-[20px] lg:px-[3%]"
      style={{
        backgroundImage:
          "linear-gradient(135deg, #e6e6f7 , #e6e6f7, #e6e6f7, #f3f3fb, #f3f3fb)",
      }}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid p-6 max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg rounded-md">
            <h2 className="text-3xl font-bold tracking-tight text-[#2822B1] sm:text-4xl">
              Sign up for updates
            </h2>
            <p className="mt-4 text-lg text-[#919FB7] leading-8 text-gray-500">
              Sign up to stay up-to-date with the latest updates and releases.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mt-6 flex flex-col max-w-md gap-y-4">
                <div className="flex gap-x-4">
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2  shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    placeholder="Your email address..."
                  />
                </div>
                <div className="flex gap-x-4 h-24">
                  <input
                    id="feedback-message"
                    name="feedback"
                    rows="4"
                    className="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 placeholder:-translate-y-6"
                    placeholder="Add your thoughts on how to make the product better (optional)"
                  />
                </div>
              </div>
              <div className="flex items-center mt-4">
                <input
                  id="no-updates"
                  name="noUpdates"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-500 border-gray-300 rounded focus:ring-indigo-500"
                />
                <label
                  htmlFor="no-updates"
                  className="ml-2 text-sm text-[#919FB7] text-gray-500"
                >
                  I do not want to receive any updates and just want to submit
                  feedback
                </label>
              </div>
              <button
                type="submit"
                className="flex-none rounded-md bg-[#2822B1] mt-[30px] px-3.5 py-2.5 text-sm font-semibold  shadow-sm hover:bg-[#2822B1] text-[white] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Sign Up
              </button>
            </form>
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <CalendarDaysIcon
                  className="h-6 w-6 text-[#2822B1]"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold ">
                Find the best travel deals
              </dt>
              <dd className="mt-2 leading-7 text-gray-500">
                Use the lastest and greatest version to ensure you are always
                optimizing your travel search
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <HandRaisedIcon
                  className="h-6 w-6 text-[#2822B1]"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold ">No spam</dt>
              <dd className="mt-2 leading-7 text-gray-500">
                We will never send spam or sell your information to third
                parties
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default EmailSection;
