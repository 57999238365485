import React from "react";
import style from "./ThreeValues.module.css";

function ValueSection() {
  return (
    <div class="container my-24 mx-auto px-[10px] ">
      <section class="mb-32 text-center ">
        <div class="flex justify-center mb-[30px]">
          <div class="max-w-[700px] text-center">
            <h2 class="mb-6 text-center text-[#2822B1] text-3xl font-bold">
              What WanderWisz offers
            </h2>
          </div>
        </div>

        <div className={`${style.container}`}>
          <div className={`${style.card}`}>
            <div class="mb-6 inline-block rounded-full bg-[#E4EBF6] p-[25px] text-[#2822B1] shadow-sm">
              <i class="fa-regular fa-circle-dot text-[36px]"></i>
            </div>
            <h5 class="mb-4 text-md font-bold">
              Accurate budgeting across flights & hotels
            </h5>
          </div>
          <div className={`${style.card}`}>
            <div class="mb-6 inline-block rounded-full bg-[#E4EBF6] px-[25px] py-[25px] text-[#2822B1] shadow-sm">
              <i class="fa-regular fa-gem text-[36px]"></i>
            </div>
            <h5 class="mb-4 text-md font-bold">
              Unique destination recommendations
            </h5>
          </div>
          <div className={`${style.card}`}>
            <div class="mb-6 inline-block rounded-full bg-[#E4EBF6] px-[25px] py-[25px] text-[#2822B1] shadow-sm">
              <i class="fa-solid fa-hand-point-right text-[36px]"></i>
            </div>
            <h5 class="mb-4 text-md font-bold">Easy and free to use tool</h5>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ValueSection;
