import React, { useState } from 'react';

function GPTAssistant() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    const handleSendMessage = async () => {
        const newMessages = [...messages, { text: input, sender: 'user' }];
        setMessages(newMessages);
    
        const response = await fetch('http://127.0.0.1:5000/api/chatbot/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message: input }),
        });
        const data = await response.json();
        setMessages([...newMessages, { text: data.message, sender: 'bot' }]);
    };

    return (
        <div className="p-4 bg-gray-100 rounded-lg">
            <div className="chat-container bg-white p-4 rounded-lg h-64 overflow-scroll">
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'} p-2 rounded-lg my-2`}>
                        {message.text}
                    </div>
                ))}
            </div>
            <div className="input-container bg-white p-4 rounded-lg mt-4 flex items-center">
                <input type="text" placeholder="Type your message..." className="flex-grow p-2 rounded-lg mr-2" value={input} onChange={e => setInput(e.target.value)} />
                <button onClick={handleSendMessage} className="bg-blue-500 text-white p-2 rounded-lg">Send</button>
            </div>
        </div>
    );
}

export default GPTAssistant;